import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import MarkdownToJsx from 'markdown-to-jsx';
import { Heading, Link, Text } from '@qga/roo-ui/components';
import startCase from 'lodash/startCase';
import { useDataLayer } from 'hooks/useDataLayer';
import { mediaQuery } from 'lib/styledSystem';
import { HOTELS_HOST, HOTELS_PATH } from 'config';

const BlockQuote = styled(Text)`
  display: block;
  font-size: ${themeGet('fontSizes.base')};

  ${mediaQuery.minWidth.sm} {
    font-size: ${themeGet('fontSizes.lg')};
  }

  > span {
    font-size: ${themeGet('fontSizes.base')};

    ${mediaQuery.minWidth.sm} {
      font-size: ${themeGet('fontSizes.lg')};
    }
  }
`;

const Paragraph = styled(Text)`
  display: block;
  font-size: ${themeGet('fontSizes.base')};
  padding-bottom: ${themeGet('space.4')};
`;

const Span = styled(Text)`
  display: inline-block;
  font-size: ${themeGet('fontSizes.base')};
  padding-bottom: ${themeGet('space.4')};
`;

const Heading1 = styled(Heading.h1)`
  font-size: ${themeGet('fontSizes.2xl')};

  ${mediaQuery.minWidth.sm} {
    font-size: ${themeGet('fontSizes.3xl')};
  }
`;

const Heading3 = styled(Heading.h3)`
  text-align: center;
  margin-bottom: ${themeGet('space.4')};
  margin-top: ${themeGet('space.10')};
`;

const Heading4 = styled(Heading.h4)`
  font-weight: ${themeGet('fontWeights.normal')};
`;

const InlineLink = styled(Link)`
  display: inline;
  text-decoration: underline;
`;

const Image = styled.img`
  max-width: 100%;
`;

const Table = styled.table`
  border: ${themeGet('borders.1')} ${themeGet('colors.greys.alto')};
  border-collapse: collapse;

  th,
  td {
    text-align: left;
    border: ${themeGet('borders.1')} ${themeGet('colors.greys.alto')};
    padding: ${themeGet('space.4')};
    vertical-align: top;
  }

  ${mediaQuery.minWidth.sm} {
    th,
    td {
      padding: ${themeGet('space.4')} ${themeGet('space.16')} ${themeGet('space.4')} ${themeGet('space.4')};
    }
  }
`;

const hotelsPropertyRegExp = new RegExp(String.raw`^${HOTELS_HOST}${HOTELS_PATH}/properties/(?:[0-9]*)?`);

const LinkWithTracking = ({ children, href, ...props }) => {
  const { emitInteractionEvent } = useDataLayer();

  const handleClick = () => {
    const isHotelsPropertyLink = hotelsPropertyRegExp.test(href);
    if (children) {
      emitInteractionEvent({
        type: `${isHotelsPropertyLink ? 'Hotel Text' : startCase(children)} Link`,
        value: `${isHotelsPropertyLink ? startCase(children) : 'Link'} Selected`,
        customAttributes: { href },
      });
    }
  };

  return (
    <InlineLink onClick={handleClick} href={href} {...props} rel="noopener noreferrer" target="_blank">
      {children}
    </InlineLink>
  );
};

LinkWithTracking.propTypes = {
  href: PropTypes.string,
  children: PropTypes.node,
};

const Markdown = ({ content, disableParsingRawHTML = true, isCustomHeading = true, customComponents = {}, ...rest }) => {
  const options = {
    disableParsingRawHTML,
    overrides: {
      a: LinkWithTracking,
      blockquote: BlockQuote,
      h1: isCustomHeading ? Heading1 : Heading.h1,
      h2: Heading.h2,
      h3: isCustomHeading ? Heading3 : Heading.h3,
      h4: Heading4,
      h5: Heading.h5,
      img: Image,
      p: Paragraph,
      span: Span,
      table: Table,
      ...customComponents,
    },
  };

  return (
    <MarkdownToJsx options={options} {...rest}>
      {content}
    </MarkdownToJsx>
  );
};

Markdown.propTypes = {
  content: PropTypes.string,
  disableParsingRawHTML: PropTypes.bool,
  isCustomHeading: PropTypes.bool,
  customComponents: PropTypes.object,
};

export default Markdown;
